const PathConstants = {
  HOME: "/",
  ABOUT: "about",
  WHATWEDO: "whatdo",
  POLICY: "/policy",
  TERMS:"/terms"
};
export const TOAST_DURATION = 5000;
export const SUCCESS_DISPLAY_TIME = 5000;

export default PathConstants;
