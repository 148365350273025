import React from "react";
import { useState, useEffect, useContext } from "react";
import emailjs from "@emailjs/browser";
import { UserContext } from "../../contexts/UserContexts";
import { SUCCESS_DISPLAY_TIME, TOAST_DURATION } from "../../utils/constants";

const ContactForm = ({ data }) => {
  useEffect(() => emailjs.init("B4YJ1jT-uj7TWFvXA"), []);
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [alert, setAlert] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const serviceId = "service_n0aup8p";
    const templateId = "template_35z2lny";
    const recipient = "support@allianca.group";

    try {
      setLoading(true);
      await emailjs.send(serviceId, templateId, {
        from_name: userName,
        recipient: recipient,
        email: email,
        message: message,
      });
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, SUCCESS_DISPLAY_TIME);

      // alert("email successfully sent check inbox");
    } catch (error) {
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
      }, TOAST_DURATION);
    } finally {
      setLoading(false);
    }
  };
  const { name, title, tagline, logo, formTitle } = data;
  const { headerHeight } = useContext(UserContext);
  if (loading) {
    return (
      <div className="w-full h-full fixed top-0 left-0 bg-white opacity-75 z-50">
        <div className="flex justify-center items-center mt-[50vh]">
          <div className="border-gray-300 h-20 w-20 animate-spin rounded-full border-8 border-t-blue-600" />
        </div>
      </div>
    );
  }

  return (
    <section
      id="contactUs"
      aria-label="Contact"
      className="scroll-section bg-indigo-900 py-14 lg:py-28 relative text-center md:text-left"
    >
      {/* alert */}
      {alert && (
        <>
          <div
            id="toast-simple"
            className="fixed top-5 right-5  pl-10   w-80  z-[1000]  flex items-center p-4 space-x-4 text-gray-500 bg-white divide-x divide-gray-200 rounded-lg shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800"
            role="alert"
          >
            Some Error occured
          </div>
        </>
      )}

      <div className="absolute w-full h-full top-0 left-0">
        <img
          className="h-full w-full object-cover"
          src="https://d326sevzymfxu0.cloudfront.net/static/images/contact-bg.png"
          alt="Blue Pattern"
        />
      </div>
      <div className="container" style={{ paddingTop: headerHeight }}>
        <div className="grid md:grid-cols-2 gap-10 items-center">
          <div className=" max-w-[537px] space-y-5  lg:space-y-8">
            <img
              src={logo}
              alt={name}
              width={91}
              height={91}
              className="mx-auto md:mx-0"
            />
            <h2 className="text-white font-normal leading-tight lg:leading-none">
              {title}
            </h2>
            <h6 className="text-white font-normal">{tagline}</h6>
          </div>
          <div className="bg-[#F9F5F2] rounded-lg md:rounded-none md:bg-blue-200 p-10 lg:p-14 space-y-10 relative">
            <i className="hidden lg:inline-block size-11 bg-indigo-900 absolute top-[-1px] left-0 [clip-path:polygon(0%_0%,100%_0%,0%_100%,0%_50%)] !mt-0"></i>

            {success ? (
              <div className="w-full h-full p-4 text-center sm:p-5">
                <button
                  type="button"
                  className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="successModal"
                  onClick={() => setSuccess(false)}
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
                <div className="w-12 h-12 rounded-full bg-green-300 p-2 flex items-center justify-center mx-auto mb-3.5">
                  <svg
                    aria-hidden="true"
                    className="w-8 h-8 text-white"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Success</span>
                </div>
                <h4 className="text-gray-700 text-center !mt-0 mb-4">
                  Thank you !
                </h4>
                <p className="mb-4 text-lg font-medium text-gray-700 text-center">
                  Your submission has been received
                </p>
              </div>
            ) : (
              <>
                <h4 className="text-gray-700 text-center !mt-0">{formTitle}</h4>
                <form className="space-y-4" onSubmit={handleSubmit}>
                  <div className="grid lg:grid-cols-2 gap-4">
                    <div>
                      <input
                        type="text"
                        name="userName"
                        placeholder="Name"
                        className="form-input"
                        onChange={(e) => setUserName(e.target.value)}
                        required
                      />
                    </div>
                    <div>
                      <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        className="form-input"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <textarea
                      rows={6}
                      placeholder="Message"
                      className="form-input"
                      onChange={(e) => setMessage(e.target.value)}
                    ></textarea>
                  </div>
                  <div className=" f-ic justify-center">
                    <button type="submit" className="btn btn-primary">
                      Send us a message
                    </button>
                  </div>
                </form>
              </>
            )}
            <i className="hidden lg:inline-block size-11  bg-indigo-900 absolute bottom-0 right-0 rotate-180 [clip-path:polygon(0%_0%,100%_0%,0%_100%,0%_50%)]"></i>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
