import React from 'react';
export const HamburgerIcon = ({
  className,
  fill,
  width,
  height,
  viewBox,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : '0 0 20 20'}
      width={width ? width : '20'}
      height={height ? height : '20'}
      fill={fill ? fill : 'none'}
    >
      <path
        fillRule="evenodd"
        d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
        clipRule="evenodd"
        fill='#201F20'
      ></path>
    </svg>
  );
};
