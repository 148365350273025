import { NavLink } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import PAGE_URL from "../utils/constants";

const Footer = () => {
  const goPageTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer className="bg-[#FAFAFA] py-8 lg:py-16 relative footer">
      <div className="container">
        <div className="md:f-ic-jb gap-10 text-center lg:text-left">
          <div>
            <Link to={PAGE_URL.HOME} onClick={goPageTop}>
              <img
                src="https://d326sevzymfxu0.cloudfront.net/static/images/allianca-logo.png"
                alt="allianca group"
                className="max-w-[180px] lg:max-w-[237px] mx-auto lg:mx-0"
                width={237}
                height={46}
              />
            </Link>
            {/* <address className="mt-4 not-italic text-gray-700">2512 Stable Door Lane, Fort Worth, TX 76244</address> */}
          </div>
          <nav
            className={`navigation absolute w-full bg-white lg:bg-transparent lg:h-auto shadow top-[82px] left-0 right-0 px-5 py-4 lg:static lg:p-0 lg:shadow-none lg:w-auto h-screen`}
          >
            <ul className="list-none flex flex-col lg:flex-row lg:items-center gap-4 lg:gap-10 *:text-[18px] *:text-gray-800 *:font-medium p-0">
              <li>
                <NavLink
                  to={PAGE_URL.WHATWEDO}
                  activeclassname="active"
                  onClick={goPageTop}
                  className="text-gray-800 hover:text-blue-500"
                >
                  What we do
                </NavLink>
              </li>
              {/* <li>
                <Link smooth
                to="/#ourTransformation"
                className="text-gray-800 hover:text-blue-500"
                >
                  Our transformation
                </Link>
              </li> */}
              <li>
                <NavLink
                  to={PAGE_URL.ABOUT}
                  activeclassname="active"
                  onClick={goPageTop}
                  className="text-gray-800 hover:text-blue-500"
                >
                  About us
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>

        <div className="navigation flex flex-col items-center sm:flex-row sm:justify-between md:mt-10 md:pt-10 md:border-t md:border-solid md:border-gray-[rgba(102,102,102,0.2)]">
          <div className="flex gap-6  *:text-[14px] *:text-gray-700 *:underline *:underline-offset-2">
            <NavLink
              to={PAGE_URL.POLICY}
              activeclassname="active"
              onClick={goPageTop}
              className="hover:text-blue-500 hover:no-underline"
            >
              Privacy Policy
            </NavLink>
            <NavLink
              to={PAGE_URL.TERMS}
              onClick={goPageTop}
              aria-label="Reddit"
              className="hover:text-blue-500 hover:no-underline"
            >
              Terms of Service
            </NavLink>
          </div>
          <p className=" no-underline text-[14px] text-gray-700 hidden md:inline-block">
            © Copyright 2024. All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
