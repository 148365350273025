import { NavLink } from "react-router-dom";
import PAGE_URL from "../utils/constants";
import { HashLink as Link } from 'react-router-hash-link';

const Navigation = ({ isToggle, setToggle }) => {
  
  const goPageTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  const closeMobileMenu = () => {
    setToggle(!isToggle);
    goPageTop();
  }
  return (
    <nav
      className={`navigation absolute w-full bg-white lg:bg-transparent lg:h-auto shadow top-[46px] left-0 right-0 px-5 py-4 lg:static lg:p-0 lg:shadow-none lg:w-auto h-screen lg:block ${
        isToggle ? "block" : "hidden"
      }`}
    >
      <ul className="list-none flex flex-col lg:flex-row lg:items-center gap-4 lg:gap-10 *:text-[18px] *:text-gray-800 *:font-medium p-0">
        <li>
          <NavLink
            to={PAGE_URL.WHATWEDO}
            onClick={closeMobileMenu}
            activeclassname="active"
            className="text-gray-800"
          >
            What we do
          </NavLink>
        </li>
        {/* <li>
          <Link
          smooth
          to="/#ourTransformation"
          className="text-gray-800"
          >
            Our transformation
          </Link>
        </li> */}
        <li>
          <NavLink
            to={PAGE_URL.ABOUT}
            activeclassname="active"
            onClick={closeMobileMenu}
            className="text-gray-800"
          >
            About us
          </NavLink>
        </li>
        <li>
          <Link
            smooth
            onClick={closeMobileMenu}
            to="/#contactUs"
            className="btn btn-secondary-outline inline-block"
          >
            Let's talk
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;