import ServicesList from "./ServicesList";

const ServicesCard = ({ cardData }) => {
  return (
    <>
      {cardData.map((cardItem, index) => {
        const { imgUrl, serviceTitle, description, serviceList, id } = cardItem;
        return (
          <figure
            key={index}
            id={id}
            className="scroll-section grid md:grid-cols-2 lg:grid-cols-[450px_1fr] gap-12  lg:gap-24 [&:not(:last-child)]:pb-10 pt-10  lg:[&:not(:last-child)]:pb-20 lg:pt-20 [&:not(:last-child)]:border-b border-solid border-[rgba(0,0,0,0.1)]"
          >
            <img src={imgUrl} alt={serviceTitle} />
            <figcaption className="space-y-8">
              <h3 className="text-gray-700 font-medium inline-block relative">
                {serviceTitle}
                <span className="h-1 md:h-[7px] w-[70%] left-0 bg-blue-500 absolute -bottom-1"></span>
              </h3>
              <p className="md:text-[18px] font-normal text-gray-700">
                {description}
              </p>
              <ServicesList serviceList={serviceList} />
            </figcaption>
          </figure>
        );
      })}
    </>
  );
};

export default ServicesCard;
