import AboutStories from "../components/about/AboutStories";
import TeamList from "../components/about/TeamList";
import TeamPageHeader from "../components/about/TeamPageHeader";
import ContactForm from "../components/home/ContactForm";
import teamData from "../data/team.json";

import HomeData from "../data/home.json";

const dataFilter = (name) => {
  const filterData = HomeData.filter((item) => item.name === name)[0];
  return filterData;
};

const About = () => {
  return (
    <main>
      <section className="py-8 lg:py-16 text-center md:text-left">
        <div className="container">
          <TeamPageHeader teamData={teamData} />
          <AboutStories teamData={teamData} />
          <TeamList teamData={teamData} />
        </div>
      </section>
      <ContactForm data={dataFilter("contact form")} />
    </main>
  );
};

export default About;
