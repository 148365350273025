import { HashLink as Link } from 'react-router-hash-link';

const OurServices = ({ data }) => {
  const { title, cards } = data;
  return (
    <section aria-label="Services" className="bg-blue-100 py-14 lg:py-28 text-center md:text-left">
      <div className="container space-y-10  lg:space-y-16">
        <h2 className="text-blue-500 font-normal capitalize">
          {title}
        </h2>
        {cards.length && (
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-12 2xl:gap-16">
            {cards.map((card, index) => {
              return (
                <figure key={index} className="flex flex-col relative group">
                  <div className='relative before:h-full  before:w-full before:bg-blue-800/70 before:absolute before:left-0 before:top-0 before:opacity-0 group-hover:before:opacity-1 flex items-center justify-center'>
                    <i className="inline-block size-11 bg-blue-100 absolute -top-[1px] -left-[1px] [clip-path:polygon(0%_0%,100%_0%,0%_100%,0%_50%)]"></i>
                    <img
                      src={card.cardImg}
                      alt={card.cardTitle}
                      width={544}
                      height={440}
                      className="aspect-square object-cover w-full"
                    />
                    <Link
                      smooth
                      to={'/whatdo#' + card.id}
                      className="btn btn-primary-outline inline-block opacity-0 group-hover:opacity-1 absolute"
                    >
                      Learn More
                    </Link>
                  </div>
                  <figcaption className="space-y-4 bg-white py-4 px-2 text-center">
                    <h5 className="text-blue-500 font-normal">
                      {card.cardTitle}
                    </h5>
                  </figcaption>
                </figure>
              );
            })}
          </div>
        )}
      </div>
    </section>
  );
};

export default OurServices;
