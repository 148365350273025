const ServicesList = ({serviceList}) => {
  return (
    <ul className="text-gray-700 font-normal md:text-[18px] space-y-4 custom-list">
      {serviceList.map((listItem, index) => {
        return (
          <li key={index}><span className="ml-4">{listItem}</span></li>
        )
      })}
    </ul>
  )
}

export default ServicesList;