import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"
import Layout from "./components/Layout"
import Home from "./pages/Home"
import About from "./pages/About"
import WhatWeDo from "./pages/WhatWeDo"
import Policy from "./pages/Policy"
import Terms from "./pages/Terms"

const App = () => {
   const router = createBrowserRouter([
    {
      element: <Layout />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/about",
          element: <About />,
        },
        {
          path: "/whatdo",
          element: <WhatWeDo />,
        },
        {
          path: "/policy",
          element: <Policy />,
        },
        {
          path: "/terms",
          element: <Terms />,
        },
      ],
    },
  ])
  return (
    <RouterProvider router={router} />
  );
}

export default App;
