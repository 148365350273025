import { LinkedinIcon } from "../../assets/icons/LinkedinIcon";

const TeamList = ({ teamData }) => {
  const { teamTitle, teamtagline, teams } = teamData;
  return (
    <div className="mt-28 space-y-16">
      <div className="grid md:grid-cols-[40%_auto] gap-10 items-center">
        <h2 className="text-blue-500 font-normal capitalize leading-tight">
          {teamTitle}
        </h2>
        <h3 className="text-gray-700 font-light leading-tight">
          {teamtagline}
        </h3>
      </div>
      <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-y-10 gap-x-5  lg:gap-0 relative">
      {/* <div className="hidden md:block absolute w-full h-full top-0 left-0">
        <img className="h-full w-full object-fill" src={teambg} alt="White Pattern" />
      </div> */}
        {teams.map((card, index) => {
          const { imgUrl, name, designation, description, linkedinUrl } = card;
          return (
            <figure
              key={index}
              className="relative border border-solid border-[#D7D7D7] rounded group grid"
            >
              <img className="object-cover absolute w-full h-full top-0 left-0" src="https://d326sevzymfxu0.cloudfront.net/static/images/white-pattern.png" alt="White Pattern" />
              <img
                src={imgUrl}
                alt={name}
                className="h-full mx-auto object-cover col-span-full row-span-full"
              />
              <figcaption className="row-span-full col-span-full bg-indigo-900 bg-opacity-90 lg:top-0 lg:h-full text-white rounded border border-solid  border-[#D7D7D7] text-center flex items-center gap-6 flex-col justify-center px-6 py-4 opacity-0 group-hover:opacity-100 transition-opacity relative z-[1]">
                <div>
                  <h5 className="text-[24px] font-normal">{name}</h5>
                  <h6 className="text-[18px] font-medium">{designation}</h6>
                </div>

                <p className="text-[14px] font-medium">{description}</p>
                {/* <hr className="my-4 h-[1px] bg-[rgba(255,255,255,0.26)] w-full" /> */}
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={linkedinUrl}
                  title="Linkedin"
                  className="flex items-center gap-2"
                >
                  <span className="text-[14px] text-white">Connect on</span>
                  <LinkedinIcon />
                </a>
              </figcaption>
            </figure>
          );
        })}
      </div>
    </div>
  );
};

export default TeamList;
