import policyData from "../data/policies.json";

const Policy = () => {
  const { title, policies } = policyData;
  return (
    <section className="py-8 lg:py-16 text-center md:text-left">
      <div className="container space-y-12 md:space-y-20">
        <h2 className="text-blue-500 font-normal capitalize">
          {title}
        </h2>
        <ul className=" list-none m-0 p-0 space-y-5  lg:space-y-10">
          {policies.map((policy, index) => {
            const { policiesTitle, description } = policy;
            return (
              <li className="text-gray-800 space-y-1.5" key={index}>
                <h5 className="font-normal">{policiesTitle}</h5>
                <h6 className="font-normal ">{description}</h6>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};

export default Policy;
