import { Link } from "react-router-dom";
import PAGE_URL from "../utils/constants";
// import { Logo } from "https://d326sevzymfxu0.cloudfront.net/static/images/allianca-logo.png";
import Navigation from "./Navigation";
import { useState, useContext } from "react";
import { HamburgerIcon}  from "../assets/icons/HamburgerIcon";
import { CrossIcon } from "../assets/icons/CrossIcon";
import { UserContext } from "../contexts/UserContexts";

const Header = () => {
  const [toggle, setToggle] = useState(false);
  const { headerRef } = useContext(UserContext);

  const handleMenuOpen = () => {
    setToggle((toggle) => !toggle);
  }
  const goPageTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  const closeMobileMenu = () => {
    goPageTop();
  }

  return (
    <header ref={headerRef} className="sticky top-0 left-0 w-full py-[22px] border-b border-solid border-[rgba(0,0,0,0.1)] bg-white/70 z-[1000]">
      <div className="container relative">
        <div className=" f-ic-jb gap-5">
          <Link to={PAGE_URL.HOME} onClick={closeMobileMenu}>
            {/* <LogoIcon className="w-[185px] lg:w-[250px] h-auto" /> */}
            <img
              src="https://d326sevzymfxu0.cloudfront.net/static/images/allianca-logo.png"
              alt="allianca group"
              width={237}
              height={46}
              className="max-w-[180px] lg:max-w-[237px]"
            />
            {/* <p className="text-[12px] md:text-[14px] text-blue-800 italic pt-[2px] pl-[27px] md:pl-[35px]">Leading Owner's Reps group </p> */}
          </Link>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={handleMenuOpen}
            >
              <span className="sr-only">Open main menu</span>
              {toggle ? (
                <CrossIcon />
              ):
                <HamburgerIcon className="w-6 h-6" />
              }
            </button>
          </div>
          <Navigation setToggle={setToggle} isToggle={toggle} />
        </div>
      </div>
    </header>
  );
};

export default Header;
