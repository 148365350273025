import servicesData from "../../data/services.json";
import ServicesCard from "./ServicesCard";

const ServicesCardList = () => {
  const { title, services } = servicesData;
  return (
    <section className="py-8 lg:py-16">
      <div className="container">
        <h2 className="text-blue-500 font-normal capitalize">
          {title}
        </h2>
        <div>
          <ServicesCard cardData={services} />
        </div>
      </div>
    </section>
  );
};

export default ServicesCardList;
