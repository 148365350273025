const TeamPageHeader = ({ teamData }) => {
  const { title, titleSuffix, tagline } = teamData;
  return (
    <div className="space-y-5 lg:space-y-6 mb-10">
      <h2 className="font-light text-gray-700">
        {title}
        <span className="text-blue-500 font-normal ml-2">{titleSuffix}</span>
      </h2>
      <h5 className="text-[20px] lg:text-[26px] font-medium text-gray-700">
        {tagline}
      </h5>
    </div>
  );
};

export default TeamPageHeader;
