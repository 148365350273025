import Banner from "../components/home/Banner";
import WhyAllianca from "../components/home/WhyAllianca";
import OurServices from "../components/home/OurServices";
import ContactForm from "../components/home/ContactForm";
import HomeData from "../data/home.json";

const dataFilter = (name) => {
  const filterData = HomeData.filter((item) => item.name === name)[0];
  return filterData;
};
const Home = () => {
  return (
    <>
      <Banner data={dataFilter("banner")} />
      <OurServices data={dataFilter("our services")} />
      <WhyAllianca data={dataFilter("why allianca")} />
      <ContactForm data={dataFilter("contact form")} />
    </>
  );
};

export default Home;
