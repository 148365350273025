import React from 'react';
export const RightTickIcon = ({
  className,
  fill,
  width,
  height,
  viewBox,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : '0 0 59 46'}
      width={width ? width : '59'}
      height={height ? height : '46'}
      fill={fill ? fill : 'none'}
    >
      <path d="M59.0052 5.33281L19.0052 45.3328L0.671875 26.9995L5.37187 22.2995L19.0052 35.8995L54.3052 0.632812L59.0052 5.33281Z" fill="#444444"/>
    </svg>
  );
};
