import { useRef, useEffect } from "react";
import React from "react";
import ReactPlayer from "react-player";

const Banner = ({ data }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    videoRef.current.playbackRate = 0.5;
  }, []);
  const {
    bannerLeadingText,
    bannerInitialText,
    bannerHighlightText,
    bannerEndText,
    bannerVideo,
  } = data;
  return (
    <section
      aria-label="Banner"
      className="hero-banner-stacked h-full"
      data-position="center"
    >
      {/* <img
        className="hero-banner-stacked-media [transform:rotateY(-180deg)]"
        src={bannerImg}
        alt={name}
        width={1920}
        height={1060}
      /> */}
      <div className="hero-banner-stacked-media">
        <ReactPlayer
          ref={videoRef}
          width={"100%"}
          height={"100%"}
          url={bannerVideo}
          playing={true}
          muted
        />
      </div>
      <div
        className="hero-banner-stacked-content container"
        data-position="center-left"
      >
        <p className="leading-text italic text-white text-center md:text-left tracking-[1.6px]">
          {bannerLeadingText}
        </p>
        <h1 className="max-w-[700px] font-light text-white text-center md:text-left">
          {bannerInitialText}{" "}
          <span className="font-medium text-indigo-300 leading-[1px] md:text-[56px] lg:text-[58px]">
            {bannerHighlightText}
          </span>{" "}
          {bannerEndText}
        </h1>
      </div>
    </section>
  );
};

export default Banner;
