import ContactForm from "../components/home/ContactForm";
import ServicesCardList from "../components/service/ServicesCardList";
import HomeData from "../data/home.json";

const dataFilter = (name) => {
  const filterData = HomeData.filter((item) => item.name === name)[0];
  return filterData;
};

const WhatWeDo = () => {
  return (
    <main>
      <ServicesCardList />
      <ContactForm data={dataFilter("contact form")} />
    </main>
  );
};

export default WhatWeDo;
