import { RightTickIcon } from "../../assets/icons/RightTickIcon";

const WhyAllianca = ({ data }) => {
  const { title, cards } = data;
  return (
    <section aria-label="Why Allianca" className="py-14 lg:py-28 text-center md:text-left">
      <div className="container space-y-10  lg:space-y-16">
        <h2 className="text-blue-500 font-normal capitalize">
          {title}
        </h2>
        {cards.length > 0 && (
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
            {cards.map((card, index) => {
              return (
                <div
                  key={index}
                  aria-label="card"
                  className="why-card shadow-cardone transition rounded px-6 py-8 flex flex-col gap-4 hover:shadow-cardHover border-t-8 border-solid border-transparent hover:border-blue-500"
                >
                  <RightTickIcon className="why-card-icon w-auto h-7 mr-auto pl-2.5 mx-auto md:ml-0 " />
                  <h4 className="text-blue-500 font-normal">
                    {card.cardTitle}
                  </h4>
                  <p className="text-gray-700 text-[18px] font-normal">
                    {card.cardDescription}
                  </p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </section>
  );
};

export default WhyAllianca;
