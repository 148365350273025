const Terms = () => {
  return (
    <section className="py-8 lg:py-16 text-center md:text-left">
      <div className="container space-y-12 md:space-y-20">
        <h2 className="text-blue-500 font-normal capitalize">
        Terms and condition
        </h2>
        <h6 className="font-medium ">Terms and condition</h6>
      </div>
    </section>
  );
};

export default Terms;
