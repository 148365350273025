const AboutStories = ({ teamData }) => {
  const { teamImgUrl, stories } = teamData;
  return (
    <div className="space-y-16">
      <figure>
        <img
          src={teamImgUrl}
          alt="Team Success Stories"
          className="lg:h-[515px] object-cover w-full object-top"
        />
      </figure>
      <ul className="grid md:grid-cols-3 list-none !mt-0">
        {stories.map((item, index) => {
          return (
            <li
              key={index}
              className=" first:bg-indigo-900 even:bg-indigo-800  last:bg-blue-500 text-white  px-12 py-6 flex gap-4 flex-col text-center"
            >
              <h3 className="font-bold">{item.storieTitle}</h3>
              <h5 className="font-light">
                {item.storieDescription}
              </h5>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default AboutStories;
