import React from 'react';
export const LinkedinIcon = ({
  className,
  fill,
  width,
  height,
  viewBox,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : '0 0 25 24'}
      width={width ? width : '25'}
      height={height ? height : '24'}
      fill={fill ? fill : 'none'}
    >
      <rect x="0.6" y="0.1" width="23.8" height="23.8" rx="3.9" fill="white" stroke="white" strokeWidth="0.2"/>
      <path d="M7.94 8.88977C8.74 8.88977 9.38 8.23977 9.38 7.44977C9.38 6.65977 8.73 6.00977 7.94 6.00977C7.15 6.00977 6.5 6.65977 6.5 7.44977C6.5 8.23977 7.15 8.88977 7.94 8.88977ZM10.75 9.97977V17.9998H13.23V14.0398C13.23 12.9898 13.43 11.9798 14.72 11.9798C16.01 11.9798 16.01 13.1798 16.01 14.0998V17.9998H18.5V13.5998C18.5 11.4398 18.04 9.77977 15.52 9.77977C14.31 9.77977 13.5 10.4398 13.17 11.0698H13.14V9.96977H10.76L10.75 9.97977ZM6.7 9.97977H9.19V17.9998H6.7V9.97977Z" fill="#24236D"/>
    </svg>
  );
};
