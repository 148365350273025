// UserContext.js
import React, { createContext, useState } from 'react';
import { useRef } from 'react';

// Create context
export const UserContext = createContext();

// Create provider component
export const UserProvider = ({ children }) => {
  const headerRef = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  
  const ScrollSection = () => {
    const height = headerRef.current.clientHeight;
    setHeaderHeight(height);
  }

  return (
    <UserContext.Provider value={{ headerRef, headerHeight, ScrollSection }}>
      {children}
    </UserContext.Provider>
  );
};